
import http from "@/common/http";
import { getLabelByValue, CurrencyRechargeType } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { dateIsBetween, getPageOffset } from "@/common/utils";
import { Dayjs } from "dayjs";
import { PaginationProps } from "ant-design-vue/es/pagination";

const columns = [
  {
    title: "序号",
    width: 100,
    dataIndex: "index",
    fixed: "left",
  },

  {
    title: "玩家游戏ID",
    width: 100,
    dataIndex: "imPlayerId",
    ellipsis: true,
    fixed: "left",
  },

  {
    title: "userId",
    width: 80,
    dataIndex: "userId",
    ellipsis: true,
  },

  {
    title: "昵称",
    width: 100,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "变化类型",
    width: 180,
    dataIndex: "rechargeType",
    ellipsis: true,
  },

  {
    title: "变化前数量",
    width: 150,
    dataIndex: "beforeAmount",
    ellipsis: true,
  },

  {
    title: "变化后数量",
    width: 150,
    dataIndex: "afterAmount",
    ellipsis: true,
  },

  {
    title: "变更数量",
    width: 150,
    dataIndex: "changeAmount",
    ellipsis: true,
  },

  {
    title: "备注",
    width: 150,
    dataIndex: "remark",
    ellipsis: true,
  },

  {
    title: "变化时间",
    width: 180,
    dataIndex: "time",
  },

  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 100,
    align: "center",
  },
];

export default defineComponent({
  components: {},
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        imPlayerId: undefined,
        userId: undefined,
        startTime: "",
        endTime: "",
        rechargeType: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //开始结束时间
    const validTimeRange = reactive({
      minDate: "",
      maxDate: "",
    });

    //获取不可选择的时间
    const disabledDate = (currentDate: Dayjs) => {
      return !dateIsBetween(validTimeRange.minDate, validTimeRange.maxDate, currentDate);
    };

    //首次进入，获取可选的日期范围
    const getTime = async () => {
      loading.value = true;
      const { data } = await http.get("us_goldcoin_change_log/time");
      validTimeRange.minDate = data.minDate;
      validTimeRange.maxDate = data.maxDate;
      search.data.startTime = data.maxDate;
      search.data.endTime = data.maxDate;

      onSearch();
    };

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_goldcoin_change_log", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      let offset = getPageOffset(pagination.current!, pagination.pageSize!);
      data.value = result.data.data.map((item: any) => {
        item.index = ++offset;
        return item;
      });

      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = validTimeRange.maxDate;
      search.data.endTime = validTimeRange.maxDate;
      onSearch();
    };

    onMounted(() => {
      getTime();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      CurrencyRechargeType,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      disabledDate,
      getLabelByValue,
    };
  },
});
